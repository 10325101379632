/* Text */

* {
  text-decoration: none !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}

a {
  cursor: pointer;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  color: #707070;
}

a:visited {
  color: #707070;
}

h1, h2, h3, h4, h5 {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  color: #707070;
  display: block;
  margin-block-start: 0;
  margin-block-end: 0;
}

p, span {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  color: #707070;
}

.center {
  text-align: center;
}

.white {
  color: white !important;
}

.petit-cap {
  font-variant-caps: all-petite-caps;
}

/* Main */
body {
  margin: 0;
  color: #707070;
}

footer {
  width: 100%;
  bottom: 0;
  position: relative;
  margin: 3vh auto;
}

.card {
  background-color: #F5F4F4;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 1em;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
  min-width: 250px;
  max-width: 74vw;
  padding: 2em;
}

.align-center {
  align-self: center;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  background-color: #F5F4F4 !important;
  color: #707070 !important;
}

.fit-width {
  width: fit-content;
}


/* Flex Boxes */
.main-flex {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.horizontal-flex {
  display: flex;
  flex-direction: row;
}

.vertical-flex {
  display: flex;
  flex-direction: column;
}

/* Forms */
.width {
  width: 275px;
  display: flex;
  justify-content: center;
}

.form-flex {
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  width: 100%;
}

/* Home Page - Not Signed In*/
.home {
  height: 100vh;
  background-image: url("https://github.com/jenna-vec/Recipe-Book/blob/master/src/components/Home/background-joanie-simon.png?raw=true");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
}

.home-title-flex {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-self: center;
  width: 15em;
  height: fit-content;
  padding: 2em;
  background-color: #f5f4f4de;
  border-radius: 1em;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.home-button {
  color: #707070 !important;
  border-color: #707070 !important;
}

.credit {
  background-color: #1976d2;
  height: 3vh;
}

.home-recipe {
  height: fit-content;
  padding: 2em 0;
  display: flex;
  justify-content: center;
  background: linear-gradient(360deg, rgba(255,255,255,0) 0%, rgba(78,127,175,0.8435968137254902) 100%);
}

.register {
  width: fit-content;
  margin: 7em 0 5em 0;
  align-self: center;
}

/* Account Settings */
.account-flex {
  margin: 3em auto 0 auto;
  grid-gap: 2em;
}

.account {
  display: flex;
  flex-direction: row;
  grid-gap: 1em;
  align-items: center;
}

.account-change {
  display: none;
  flex-direction: row;
  align-items: center;
  margin: 17px, 0, 1em, 0;
  grid-gap: 4px;
  cursor: pointer;
}

#confirm-delete {
  text-align: center;
  display: none;
}

#password-change {
  display: none;
}



/* Dashboard (Home Page) */

.dash {
  margin-top: 3em;
  width: fit-content;
  align-self: center;
}

.dash-card {
  display: flex;
  max-width: 91vw;
  grid-gap: 2em;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.css-fzm7i {
  overflow: scroll;
}

/* Food Category Input */
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  text-align: left;
}

/* Plus Sign */
.css-55fg2l-MuiSvgIcon-root {
  cursor: pointer;
}

.plus {
    margin: 2em 37vw !important;
}

.add-flex {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: fit-content;
  margin: 2em auto;
  grid-gap: 2em;
}

.css-102tef7 {
  text-align: left !important;
}

.ingredients-list {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1em;
  row-gap: 0;
  justify-content: space-between;
  width: 70vw;
}

.gap {
  grid-gap: 3px;
}

.column {
  width: 70vw;
  height: 0;
}

.css-1cvn495-MuiSvgIcon-root {
  cursor: pointer;
}

.loading-div {
  align-items: center;
  justify-content: center;
  display: none;
}

.loading {
  animation: rotation 2s infinite linear;
}

.card-flex {
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  margin-top: 1em;
}

.css-9mgopn-MuiDivider-root {
  margin-top: 0.5em !important;
}


/* Recipe Page */
.no-margin-block {
  margin-block-end: 0;
  margin-block-start: 0;
}

.recipe-card-div {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  grid-gap: 1em;
}

.ingredients {
  min-width: 20vw;
}

.p-margin {
  margin-block-start: 0.1em !important;
  margin-block-end: 0.1em !important;
}

.recipe-width {
  margin: 3em auto 1em auto;
  width: 81vw;
}

.fab {
  text-align-last: right;
  margin: 0 8vw 0 0;
}

.recipe-card {
  background-color: #F5F4F4;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
  height: 75vh;
  overflow: scroll;
  min-width: 250px;
  max-width: 74vw;
  padding: 2em;
  align-self: center;
}

.info {
  margin-block-start: 4px;
  margin-block-end: 17px;
  font-style: italic;
  font-size: smaller;
}




/* Animation */

@keyframes rotation {
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
}


/* Landscape */
@media only screen
  and (orientation: landscape) {
    .css-fzm7i {
      height: 78vh;
      width: 78vw;
    }
    .plus {
      margin: 2em 2em 0 0 !important;
      float: right;
    }
    .recipe-card {
      min-width: 26vw;
      max-width: 67vw;
    }
    .recipe-card-div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      grid-gap: 3em;
    }
    .dash {
      margin-top: 10vh;
    }  
}

/* IPAD */
@media only screen 
  and (min-device-width: 481px) 
  and (max-device-width: 1024px) 
  and (orientation:portrait) {
    .credit {
      height: 2vh;
    }
    .plus {
      margin: 2em 2em 0 0 !important;
      float: right;
    }
    .add-flex {
      height: 67vh;
      width: 62vw;
    }
    .ingredients-list {
      width: 59vw;
    }
    .column {
      width: 59vw;
    } 
    .recipe-card {
      max-width: 72vw;
      padding: 2.5em;
    }  
    .recipe-card-div {
      flex-direction: row;
      justify-content: center;
      margin-top: 2em;
      grid-gap: 4em;
    }
    .dash {
      margin: 12vh 0 28vh 0;
    }
}
  
/* Laptop */
@media screen 
  and (min-width: 1440px)
  and (orientation: landscape) {
    .home-title-flex {
      padding: 2em 4em;
    }
    .plus {
      margin: 2em 8vw 0 0 !important;
    }
    .register {
      margin: 20vh 0 20vh 0;
    }
    .add-flex {
      height: 62vh;
      width: 62vw;
    }
    .ingredients-list {
      width: 28vw;
    }
    .column {
      width: 28vw;
    }  
    .recipe-card {
      max-width: 56vw;
      padding: 4em;
      height: 60vh;
    }
    .ingredients {
      min-width: 17vw;
    }
    .instructions {
      max-width: 23em;
    }
    .recipe-card-div {
      grid-gap: 3em;
    } 
    .fab {
      text-align-last: right;
      margin: 0 23vw 0 0;
    }
}

/* Larger */
@media screen 
    and (min-device-width: 1920px)
    and (orientation: landscape){
      .plus {
        margin: 2em 0vw 0 0 !important;
      }
      .recipe-card {
        width: 40vw;
        padding: 3em;
      }
      .recipe-card-div {
        margin-top: 3em;
     }
     .fab {
      margin: 0 28vw 0 0;
     }
}